"use client";

import React, { useEffect } from "react";
import AnalytycsContext from "./context";
import { AnalyticsPageType } from "@/utils/analitics";
import { GoogleAnalyticsConfig, PageData, PageType } from "@/types/site";

type Props = React.PropsWithChildren<{
  page: PageData | null;
  config?: GoogleAnalyticsConfig;
}>;

function getAnalyticsPageType(page: PageData): AnalyticsPageType {
  if (page.pageType === PageType.SRP) {
    return AnalyticsPageType.itemlist;
  }
  if (page.pageType === PageType.VDP) {
    return AnalyticsPageType.item;
  }
  if (page.pagePath === "/") {
    return AnalyticsPageType.home;
  }

  if (page.pagePath.includes("service")) {
    return AnalyticsPageType.service;
  }

  if (page.pagePath.includes("financ")) {
    return AnalyticsPageType.finance;
  }

  if (page.pagePath.includes("credit")) {
    return AnalyticsPageType.credit;
  }

  if (page.pagePath.includes("contact")) {
    return AnalyticsPageType.contact;
  }

  if (page.pagePath.includes("trade")) {
    return AnalyticsPageType.trade;
  }

  if (page.pagePath.includes("parts")) {
    return AnalyticsPageType.parts;
  }

  return AnalyticsPageType.custom;
}

const AnalytycsProvider: React.FC<Props> = ({ children, page, config }) => {
  const pageType = page ? getAnalyticsPageType(page) : AnalyticsPageType.custom;
  useEffect(() => {
    window.dataLayer = window.dataLayer || [];
    window.gtag = function () {
      window.dataLayer.push(arguments);
    };
  }, []);

  useEffect(() => {
    if (!config) {
      return;
    }

    window.gtag?.("config", config.measurementId, {
      // Use it to debug analytics in real time
      // debug_mode: true,
      page_type: pageType,
    });
  }, [config, pageType]);
  return <AnalytycsContext.Provider value={{ pageType }}>{children}</AnalytycsContext.Provider>;
};

export default AnalytycsProvider;
